import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import MessageContentForm from "../../common/MessageContentForm";
import BasicModalWithHeader from "../../global/Modal/BasicModalWithHeader";
import {
  COMPANY_STATUS,
  COMPANY_STATUS_TEXT,
} from "../../helpers/constant/coreConstant";
import { showErrorToaster } from "../../helpers/utils/toaster";
import useBoolean from "../../hooks/useBoolean";
import adminAuthService from "../../services/adminAuth.service";
import { updateCompanyStatus } from "../../state/companyList/companyListSlice";
import PaymentModal from "./PaymentModal";

const ITEM_HEIGHT = 48;

const CompanyMenuAction = ({ companyInfo }) => {
  const dispatch = useDispatch();
  const { id, status = 0, ownerInfo } = companyInfo || {};
  const targetStatus =
    status === COMPANY_STATUS.ACTIVE
      ? COMPANY_STATUS.INACTIVE
      : COMPANY_STATUS.ACTIVE;

  const { value: openModal, setFalse: onCloseModal } = useBoolean(false);
  const {
    value: openPaymentModal,
    setTrue: onOpenPaymentModal,
    setFalse: onClosePaymentModal,
  } = useBoolean(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = (status) => {
    dispatch(updateCompanyStatus({ companyId: id, status: status }));
    handleMenuClose();
  };

  const hanldeClosePaymentModal = () => {
    onClosePaymentModal();
    handleMenuClose();
  };

  const handleAdminLogin = () => {
    if (ownerInfo?.id) {
      adminAuthService
        .forceLoginEmployerAccount({ id: ownerInfo.id })
        .then((response) => {
          handleMenuClose();

          if (response.status) {
            var PREFIX = "employer_info";
            window.open(
              response.data.redirectUri,
              PREFIX + JSON.stringify({ token: response.data.token })
            );
          } else {
            showErrorToaster(response.message);
          }
        })
        .catch((err) => console.log("Error:: ", err));
    }
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        <MenuItem onClick={() => handleStatusChange(targetStatus)}>
          {COMPANY_STATUS_TEXT[targetStatus]}
        </MenuItem>
        {companyInfo.status === COMPANY_STATUS.PENDING && (
          <MenuItem onClick={() => handleStatusChange(COMPANY_STATUS.INACTIVE)}>
            Blocked
          </MenuItem>
        )}
        <MenuItem onClick={handleAdminLogin}>Force Login</MenuItem>
        <MenuItem onClick={onOpenPaymentModal}>Payment</MenuItem>
      </Menu>
      <BasicModalWithHeader
        title={"Select Payment method"}
        open={openPaymentModal}
        onClose={hanldeClosePaymentModal}
      >
        <PaymentModal
          close={hanldeClosePaymentModal}
          companyInfo={companyInfo}
        />
      </BasicModalWithHeader>
      <BasicModalWithHeader
        title={"Message Content"}
        open={openModal}
        onClose={onCloseModal}
      >
        <MessageContentForm close={onCloseModal} />
      </BasicModalWithHeader>
    </>
  );
};

export default CompanyMenuAction;
