import { all, call, put, takeLatest } from "redux-saga/effects";
import CompanyListService from "../../services/companyList.service";
import {
  getCompanyListFailed,
  getCompanyListSuccess,
  updateCompanyPaymentModeFailed,
  updateCompanyPaymentModeSuccess,
  updateCompanyStatusFailed,
  updateCompanyStatusSuccess,
} from "./companyListSlice";
import {
  showErrorToaster,
  showSuccessToaster,
} from "../../helpers/utils/toaster";

function* companyListWatcher() {
  yield takeLatest("companyList/getCompanyList", getCompanyListSaga);
  yield takeLatest("companyList/updateCompanyStatus", updateCompanyStatusSaga);
  yield takeLatest(
    "companyList/updateCompanyPaymentMode",
    updateCompanyPaymentModeSaga
  );
}

function* getCompanyListSaga(action) {
  try {
    const response = yield call(
      CompanyListService.getCompanyList,
      action.payload
    );

    if (response.status) {
      yield put(getCompanyListSuccess(response.data));
    } else {
      yield put(getCompanyListFailed(response.message));
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

function* updateCompanyStatusSaga(action) {
  try {
    const response = yield call(
      CompanyListService.updateCompanyStatus,
      action.payload
    );

    if (response.status) {
      yield put(updateCompanyStatusSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(updateCompanyStatusFailed(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}
function* updateCompanyPaymentModeSaga(action) {
  try {
    const response = yield call(
      CompanyListService.updateCompanyPaymentMode,
      action.payload
    );

    if (response.status) {
      yield put(updateCompanyPaymentModeSuccess(action.payload));
      showSuccessToaster(response.message);
    } else {
      yield put(updateCompanyPaymentModeFailed(response.message));
      showErrorToaster(response.message);
    }
  } catch (err) {
    console.log("Error: ", err);
  }
}

export default function* companyListSaga() {
  yield all([companyListWatcher()]);
}
