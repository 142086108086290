import React, { useEffect } from "react";
import Select from "react-select";
import { Box, Button, FormControl, FormLabel, Stack } from "@mui/material";
import { PAYMENT_GATEWAY_LIST } from "../../helpers/constant/coreConstant";
import { useDispatch } from "react-redux";
import { selectCompanyListState } from "../../state/companyList/companyListSelector";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  updateCompanyPaymentMode,
  updateCompanyPaymentModeReset,
} from "../../state/companyList/companyListSlice";

const PaymentModal = ({ close, companyInfo }) => {
  const dispatch = useDispatch();
  const { isUpdating, isUpdateSuccess } = useSelector(selectCompanyListState);

  const { control, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
  });

  useEffect(() => {
    if (companyInfo?.paymentMode) {
      const paymentMode = PAYMENT_GATEWAY_LIST.find(
        (data) => data.value === companyInfo.paymentMode
      );
      setValue("paymentMode", paymentMode);
    }
  }, [companyInfo]);

  useEffect(() => {
    if (isUpdateSuccess) {
      close();
      dispatch(updateCompanyPaymentModeReset());
    }
  }, [dispatch, isUpdateSuccess]);

  const onSubmit = (data) => {
    if (companyInfo.id) {
      data.companyId = companyInfo.id;
      data.paymentMode = data.paymentMode.value;

      dispatch(updateCompanyPaymentMode(data));
    }
  };

  const isLoading = isUpdating;
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack direction={"column"} spacing={3}>
        <Controller
          control={control}
          name="paymentMode"
          defaultValue=""
          rules={{ require: true }}
          render={({
            field: { value, onChange },
            fieldState: { invalid, error },
          }) => (
            <FormControl
              fullWidth
              required
              error={!!(invalid && error.message)}
            >
              <FormLabel>Payment Mode</FormLabel>

              <Select
                placeholder={"Select Payment method"}
                className={"select-dropdown-menu select-dropdown-menu-custom"}
                classNamePrefix={"select-dropdown-styles"}
                options={PAYMENT_GATEWAY_LIST}
                isSearchable={true}
                value={value}
                onChange={onChange}
                error={!!(invalid && error.message)}
                helperText={invalid && error.message}
              />
            </FormControl>
          )}
        />

        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          mt={4}
          spacing={1.3}
        >
          <Button variant={"text"} onClick={close}>
            Cancel
          </Button>
          <Button type={"submit"} variant={"contained"} size={"medium"}>
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PaymentModal;
