import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import useBoolean from "../../hooks/useBoolean";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { selectCompanyListState } from "../../state/companyList/companyListSelector";
import { getCompanyList } from "../../state/companyList/companyListSlice";
import TableLoaderSkeleton from "../../global/Loader/TableLoaderSkeleton";
import GlobalEmptyPage from "../../common/GlobalEmptyPage/GlobalEmptyPage";
import {
  COMPANY_STATUS_COLOR,
  COMPANY_STATUS_TEXT,
  USER_STATUS,
} from "../../helpers/constant/coreConstant";
import CompanyMenuAction from "./CompanyMenuAction";
import GlobalTablePagination from "../../global/Pagination/GlobalTablePagination";
import { getFullName } from "../../helpers/utils/getFullName";
import CompaniesHeader from "./CompaniesHeader";

const headCells = [
  {
    id: "Company Name",
    label: "Company Name",
  },
  {
    id: "Linkedin",
    label: "Linkedin",
  },
  {
    id: "Account Email",
    label: "Account Email",
  },
  {
    id: "Joining Date",
    label: "Joining Date",
  },
  {
    id: "Package Type",
    label: "Package Type",
  },
  {
    id: "Subscription Date",
    label: "Subscription Date",
  },
  {
    id: "Package Expiry Date",
    label: "Package Expiry Date",
  },
  {
    id: "Status",
    label: "Status",
  },
  {
    id: "Actions",
    label: "Actions",
  },
];

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="left" padding={"normal"}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const Companies = () => {
  const dispatch = useDispatch();
  const {
    data: companyList,
    isLoading,
    page,
    perPage,
    count,
  } = useSelector(selectCompanyListState);
  const {
    value: openDrawer,
    setTrue: onOpenDrawer,
    setFalse: onCloseDrawer,
  } = useBoolean(false);
  useEffect(() => {
    dispatch(getCompanyList({ page: 1, perPage: perPage }));
  }, [dispatch, perPage]);

  const onChangePage = (newPage) => {
    dispatch(getCompanyList({ page: newPage, perPage: perPage }));
  };

  const onChangePerPage = (perPage, newPage) => {
    dispatch(getCompanyList({ page: newPage, perPage: perPage }));
  };

  let content = null;
  if (isLoading) {
    content = <TableLoaderSkeleton columns={6} numberOfRows={10} />;
  } else if (companyList?.length === 0) {
    content = <GlobalEmptyPage title={"No Company found!"} />;
  } else if (companyList?.length > 0) {
    content = (
      <div>
        <TableContainer>
          <Table sx={{ minWidth: 750 }}>
            <TableHeader />
            <TableBody>
              {companyList.map((companyInfo) => {
                const {
                  name,
                  totalJobPost,
                  totalActiveJobPost,
                  status,
                  ownerInfo,
                  createdAt,
                  packageInfo,
                } = companyInfo || {};
                const { email } = ownerInfo || {};
                const {
                  name: packageName,
                  packageType,
                  expiredDate,
                  createdAt: subscriptionDate,
                } = packageInfo || {};

                const joiningDate = format(new Date(createdAt), "dd MMM, yyyy");
                const formattedExpiredDate = expiredDate
                  ? format(new Date(expiredDate), "dd MMM, yyyy")
                  : "-";
                const formattedSubscriptionDate = subscriptionDate
                  ? format(new Date(subscriptionDate), "dd MMM, yyyy")
                  : "-";

                return (
                  <>
                    <TableRow key={companyInfo.id} hover>
                      <TableCell
                        align="left"
                        onClick={onOpenDrawer}
                        sx={{ cursor: "pointer" }}
                      >
                        <Typography
                          variant={"body1"}
                          title={companyInfo.name}
                          className={"text-truncate"}
                          sx={{ width: 150 }}
                        >
                          {companyInfo?.name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <a
                          href={companyInfo?.linkedInLink || "-"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {companyInfo?.linkedInLink || "-"}
                        </a>
                      </TableCell>
                      <TableCell
                        align="left"
                        title={
                          getFullName(
                            companyInfo?.ownerInfo?.firstName,
                            companyInfo?.ownerInfo?.lastName
                          ) || "-"
                        }
                      >
                        <Typography variant="body2">{email || "-"} </Typography>
                        {ownerInfo?.status === USER_STATUS.ACTIVE ? (
                          <Typography variant="caption" color="success.main">
                            <strong>[Verified] </strong>
                          </Typography>
                        ) : (
                          <Typography variant="caption" color="secondary">
                            <strong>[Not Verified] </strong>
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="left">{joiningDate}</TableCell>
                      <TableCell align="left">{packageType || "-"}</TableCell>
                      <TableCell align="left">
                        {formattedSubscriptionDate}
                      </TableCell>
                      <TableCell align="left">{formattedExpiredDate}</TableCell>
                      <TableCell align="left">
                        <Chip
                          label={COMPANY_STATUS_TEXT[status || 0]}
                          color={COMPANY_STATUS_COLOR[status]}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <CompanyMenuAction companyInfo={companyInfo} />
                      </TableCell>
                    </TableRow>
                    {/* <BasicDrawer
                      title={"Company Details " + companyInfo?.name}
                      open={openDrawer}
                      onClose={onCloseDrawer}
                    >
                      <Typography variant="body1">
                        <b>Company name: </b> {companyInfo?.name}
                      </Typography>
                      <Typography variant="body1">
                        <b>Company Email: </b> {companyInfo?.email}
                      </Typography>
                      <Typography variant="body1">
                        <b>Company website: </b> {companyInfo?.website}
                      </Typography>
                    </BasicDrawer> */}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <GlobalTablePagination
          count={count}
          page={page}
          rowsPerPage={perPage}
          onChangePerPage={onChangePerPage}
          onChangePage={onChangePage}
        />
      </div>
    );
  }

  return (
    <>
      <Paper elevation={0}>
        <CompaniesHeader />
        {content}
      </Paper>
    </>
  );
};
export default Companies;
