export const EMPLOYER_TEMPLATE_TYPE = {
    FOR_MESSAGE: 1,
    FOR_JOB_DESCRIPTION: 2,
    FOR_INTERVIEW: 3,
    FOR_CANDIDATE_REJECTION: 4,
}

export const EMPLOYER_TEMPLATE_TYPE_LIST = {
    [EMPLOYER_TEMPLATE_TYPE.FOR_MESSAGE]: {label: "Message", value: EMPLOYER_TEMPLATE_TYPE.FOR_MESSAGE},
    [EMPLOYER_TEMPLATE_TYPE.FOR_INTERVIEW]: {label: "Interview", value: EMPLOYER_TEMPLATE_TYPE.FOR_INTERVIEW},
    [EMPLOYER_TEMPLATE_TYPE.FOR_CANDIDATE_REJECTION]: {label: "Rejection", value: EMPLOYER_TEMPLATE_TYPE.FOR_CANDIDATE_REJECTION},
}

export const EMPLOYER_TEMPLATE_STATUS = {
    PUBLISH: 1,
    UN_PUBLISH: 0
}

export const EMPLOYER_TEMPLATE_STATUS_LIST = {
    [EMPLOYER_TEMPLATE_STATUS.PUBLISH]: {label: "Published", value: EMPLOYER_TEMPLATE_STATUS.PUBLISH},
    [EMPLOYER_TEMPLATE_STATUS.UN_PUBLISH]: {label: "Unpublished", value: EMPLOYER_TEMPLATE_STATUS.UN_PUBLISH},
}
